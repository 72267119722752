
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
   required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Operational Checklist Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Operational Checklist Report",
      items: [
        {
          text: "Operational Checklist Report",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
   
      templateArr:[],
      templateID:"",
      templateTaskArr:[],
      isEdit : 0,
      isViewOnly:0,
      isDisabled: (this.$storageData.login_type==3) ? true : false,

     
    };
  },

    validations: {
    
      templateID: {
        required,
      },


  
  },
  methods:{

  submitForm(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
         window.scrollTo(0,0);
        return;
      } else {
          let formData = new FormData();  
  
            formData.append('templateID', this.templateID.complianceTemplateID);
            formData.append('templateTaskArr',JSON.stringify(this.templateTaskArr));
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('isEdit',this.isEdit);
            formData.append('loginTypeID',this.$storageData.login_type);
   
           this.axios.post(this.$loggedRole+"/addComplianceReport",formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;
           // this.$router.go();
              setTimeout(() => {
                  this.$router.push({name: 'operationalChecklistTable'}); 
              },2000);
        
         // console.warn(result);

      })
    .catch((error)=> {
       this.loading = false;
                        alert(error.response.data.data);
                        
                    });
      }  
    },




// getRestaurantTemplates(){
 
//     this.axios.post(this.$loggedRole+"/getRestaurantTemplates")
//           .then((result)=>{
//             this.templateArr = result.data.data;
              
//         });
// },
getTemplateData(complianceTemplateID){
    this.axios.post(this.$loggedRole+"/getTemplateData",{'complianceTemplateID':complianceTemplateID,'isEdit':this.isEdit})
          .then((result)=>{
            this.templateTaskArr = result.data.data;
            if(result.data.templateName){
              this.templateID = result.data.templateName;
            }
              
        });
},

getAchievements(selfScore,maxScore,weightage,index){
    let achievedScore = (parseInt(selfScore)*parseInt(weightage)) / maxScore ;
    let score = achievedScore.toFixed(2)
    this.templateTaskArr[index].achieved = score ;
     
    return score;
},

 updateValue(event,maxScore,index) {
    const value = event.target.value

      if (String(value) > maxScore) {
        this.templateTaskArr[index].selfScore = maxScore ;
      }
      this.$forceUpdate()
}

  

  },
  computed: {
    totalSelfScore(){
        return this.templateTaskArr.reduce((acc, item) => acc + parseInt(item.selfScore), 0);
    },

    totalAchieved(){
        let total = this.templateTaskArr.reduce((acc, item) => acc + parseInt(item.achieved), 0);
        let totalAchieved = total.toFixed(2)
        return totalAchieved;
    }
},

created(){
    this.complianceTemplateID = this.$route.params.id;
    if(this.complianceTemplateID){
      sessionStorage.setItem('complianceTemplateID',this.complianceTemplateID);// Save to sessionStorage
      
    }
     if(this.$route.params.type=='edit'||this.$route.params.type=='view'){
      this.isEdit = 1;
      if(this.$route.params.type=='view'){
        this.isViewOnly = 1;
      }
    }else{
      this.isEdit = 0;
    }
},
mounted(){

   // this.getRestaurantTemplates();
   if(this.isEdit==1 && sessionStorage.getItem('complianceTemplateID')>0){
      this.complianceTemplateID = sessionStorage.getItem('complianceTemplateID');
      this.isEdit = 1;
      this.getTemplateData(this.complianceTemplateID);
      
   }else{
      this.complianceTemplateID = sessionStorage.getItem('complianceTemplateID');
      this.getTemplateData(this.complianceTemplateID);
   }
    
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="templateID.templateName" :items="items" />
      <div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Record added Succesfully!
    </b-alert>
  </div>
        <!-- <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                   

                     <div class="col-md-6">
                        <label class="form-label" for="formrow-endTime-input">Select Restaurant Template*</label>
                        <multiselect v-model="templateID" :disabled="isEdit" :options="templateArr" track-by="complianceTemplateID" label="templateName" @input="getTemplateData(templateID.complianceTemplateID)"
                         :class="{
                            'is-invalid': submitted && $v.templateID.$error,
                          }" ></multiselect>
                        <div
                          v-if="submitted && $v.templateID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.templateID.required"
                            >This value is required.</span
                          >
                        </div>
                    </div>
                </div>

           

            
        </div> 
            </div>
        </div> -->

  <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h6> Task List </h6>

                     <div class="table-responsive">
                                    <table class="table table-striped mb-0" style="margin-top:15px;">
                                        <thead>
                                          <tr>
                                          <td>Tasks</td>
                                          <td>Weightage</td>
                                          <td>Max Score</td>
                                          <td>Self Score</td>
                                          <td>Achieved</td>
                                          <td>Remarks</td>
                                          </tr>
                                        </thead>
                                        <tbody v-for="(data, index) in templateTaskArr" :key="data.id">
                                            <tr>
                                            
                                                
                                                <td class="normalWrap">{{data.taskTitle}}</td>
                                                <td>{{data.weightage}} %</td>
                                                <td>{{data.maxScore}}</td>
                                                <td>  <input class="form-control col" 
                                                  type="number" :readonly="isViewOnly == 1" v-model="data.selfScore" min="0" :max="data.maxScore" @input="updateValue($event,data.maxScore,index)" :disabled="isDisabled"> 
                                                </td>

                                                <td>
                                                   {{getAchievements(data.selfScore,data.maxScore,data.weightage,index)}} % 
                                                      <input type="hidden"  v-model="data.achieved"> 
                                                </td>
                                                <td>
                                                  <input class="form-control" type="text" :readonly="isViewOnly == 1" v-model="data.remarks" :disabled="isDisabled"> 
                                                </td>

                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                              <td><b>Total</b></td>
                                              <td></td>
                                              <td></td>
                                              <td>{{totalSelfScore}}</td>
                                              <td>{{totalAchieved}} %</td>
                                            </tr>
                                          </tfoot>
                                    </table>
                                    </div>


                    <!-- <div class="inner-repeater mb-4">
                    <div class="inner mb-3">
                    <div class="row">
                        <label class="col-md-3">Tasks :</label>
                        <label class="col-md-1">Weightage:</label>
                        <label class="col-md-1">Max Score:</label>
                        <label class="col-md-2">Self Score:</label>
                        <label class="col-md-2">Achieved:</label>
                        <label class="col-md-3">Remarks:</label>
                    </div>

                      <div
                        v-for="(data, index) in templateTaskArr"
                        :key="data.id"
                        class="inner mb-3 row"
                      >
                        <div class="col-md-3">
                          {{data.taskTitle}}
                          
                        </div>
                        <div class="col-md-1">
                          {{data.weightage}} %
                          
                        </div>
                        <div class="col-md-1">
                          {{data.maxScore}}
                          
                        </div>
                        
                       
                        <div class="col-md-2">
                            <div class="row">
                            <input class="form-control col" 
                            type="number"  v-model="data.selfScore" :max="data.maxScore"> 
                            </div>
                        </div>

                        <div class="col-md-2">
                           {{getAchievements(data.selfScore,data.maxScore,data.weightage,index)}} % 
                           <input type="hidden" v-model="data.achieved"> 
                        </div>

                        <div class="col-md-3">
                          <input class="form-control" 
                            type="text"  v-model="data.remarks"> 
                        </div>

                      
                         
                      </div>
                    </div>
                  
                  </div> -->
                 
                 


                    <div class =" row" style="float:right;">
                            <b-spinner v-if="loading"
                            class="m-2 col-3"
                            variant="primary"
                            role="status"
                        ></b-spinner>
                         <button type="button" v-if="this.$storageData.login_type!=3&&isViewOnly==0" class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                         :disabled="loading" @click="submitForm()">Submit</button>
                    </div>

         </div> 
            </div>
        </div>




  

</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
.normalWrap
{
  white-space: normal;
}

</style>


